//Importing core components
import header from "assets/header.png";

//Importing styles
import styles from "./styles.module.css";

const PreviewWrapper = ({ children }) => {
  return (
    <div className={styles.PreviewWrapper}>
      <img src={header} alt="" className={styles.header} />
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default PreviewWrapper;
