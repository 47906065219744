//Importing core components
import { Container, Grid } from "@mui/material";
import PreviewWrapper from "components/UI/PreviewWrapper/PreviewWrapper";
import ProductCard from "components/UI/ProductCard/ProductCard";
import Welcome from "components/UI/Welcome/Welcome";

//Importing styles
import styles from "./styles.module.css";

const Preview = ({ data }) => {
  return (
    <PreviewWrapper>
      <Welcome data={data.welcome} />
      <div className={styles.solutions}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            {data.categories.map((item, i) => (
              <Grid item xs={12} sm={6} md={4} key={i}>
                <ProductCard item={item} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </PreviewWrapper>
  );
};

export default Preview;
