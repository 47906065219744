//Importing helper functions
import { getUrl } from "utils/modules";

//Importing core components
import { Container, Grid } from "@mui/material";
import PreviewWrapper from "components/UI/PreviewWrapper/PreviewWrapper";
import Welcome from "components/UI/Welcome/Welcome";
import { Fade } from "react-reveal";

//Importing styles
import styles from "./styles.module.css";
import Slab from "components/UI/Slab/Slab";

const Preview = ({ data }) => {
  return (
    <>
      <Fade bottom>
        <PreviewWrapper>
          <Welcome data={data.welcome} />
          <Container maxWidth="lg">
            <div className={styles.images}>
              <h2>Explore Our Gallery</h2>
              <Slab />
              <p>
                We excute all our projects with a dose of professionalism and
                perfection.
                <br /> Have an insight of our works.
              </p>
              <Grid container spacing={1}>
                {[...data.photos].slice(0, 6).map((item, i) => (
                  <Grid item xs={12} sm={6} md={4}>
                    <Fade delay={i > 2 ? (i - 2) * 200 : i * 200}>
                      <div className={styles.image}>
                        <div
                          style={{
                            backgroundImage: `url("${getUrl(item.image)}")`,
                          }}
                        />
                      </div>
                    </Fade>
                  </Grid>
                ))}
              </Grid>
            </div>
          </Container>
        </PreviewWrapper>
      </Fade>
    </>
  );
};

export default Preview;
