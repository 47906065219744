//Importing helper functions
import * as utils from "react";
import { Fade } from "react-reveal";

//Importing core components
import Heading from "components/UI/Heading/Heading";
import Manage from "./sections/Manage";

//Importing styles
import styles from "./style.module.css";
import { Container, Grid } from "@mui/material";
import ProductCard from "components/UI/ProductCard/ProductCard";

const ManageCategories = ({ data, refetchData }) => {
  const [item, setItem] = utils.useState(null);

  return (
    <>
      <Fade bottom>
        <div className={styles.ManageServices}>
          <Heading
            title={`Categories Available In Database.(${data.length})`}
          />
          <div className={styles.box}>
            <Container maxWidth="lg">
              <Grid container spacing={3}>
                {data.map((item, i) => (
                  <Grid item xs={12} sm={6} md={4} key={i}>
                    <ProductCard
                      item={item}
                      edit
                      editItem={(value) => {
                        document.getElementById("edit").scrollIntoView();
                        setItem(value);
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Container>
          </div>
          <Heading
            title={item ? "Edit Category Details." : "Publish New Category."}
          />
          <Manage
            id={item ? item.SK : null}
            data={item}
            setItem={(value) => setItem(value)}
            refetchData={refetchData}
          />
        </div>
      </Fade>
    </>
  );
};

export default ManageCategories;
