//Importing styles
import styles from "./styles.module.css";

const Slab = () => {
  return (
    <div className={styles.slab}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Slab;
