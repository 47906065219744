//Importing helper functions
import { getUrl } from "utils/modules";

//Importing core components
import { Button } from "@mui/material";

//Importing styles
import styles from "./styles.module.css";

const SolutionCard = ({ item, color, edit, editItem, deleteItem }) => {
  return (
    <div className={styles.solution}>
      {edit && (
        <div className={styles.edit}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => editItem(item)}
            size="small"
          >
            Edit
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => deleteItem(item.SK)}
            size="small"
          >
            Delete
          </Button>
        </div>
      )}
      <div className={styles.image}>
        <div
          className={styles.img}
          style={{
            backgroundImage: `url("${getUrl(item.image)}")`,
          }}
        />
      </div>
      <div className={styles.info}>
        <h4>{item.heading}</h4>
        <p style={{ color: color }}>{item.description}</p>
        <button className={styles.btn}>Read More</button>
      </div>
    </div>
  );
};

export default SolutionCard;
