//Importing helper functions
import { getUrl } from "utils/modules";

//Importing styles
import styles from "./styles.module.css";

const Welcome = ({ data, prevPage }) => {
  return (
    <div
      className={styles.Welcome}
      style={{
        backgroundImage: `linear-gradient(rgba(12,90,219, 0.85), rgba(12,90,219, 0.85)),url("${getUrl(
          data.image
        )}")`,
      }}
    >
      <h1
        dangerouslySetInnerHTML={{
          __html: data.heading,
        }}
      />
      <ul>
        <li>
          <a onClick={e => e.preventDefault()} href="/">Home</a>{" "}
        </li>
        <li>
          <i className="icofont-rounded-right"></i>
        </li>
        {prevPage && (
          <>
            <li>
              <a onClick={e => e.preventDefault()} href="/">{prevPage.title}</a>
            </li>
            <li>
              <i className="icofont-rounded-right"></i>
            </li>
          </>
        )}
        <li>
          <a onClick={e => e.preventDefault()} href={data.href}>{data.tag}</a>{" "}
        </li>
      </ul>
    </div>
  );
};

export default Welcome;
