export const routes = (groups) => {
  const paths = [
    {
      title: "Dashboard",
      icon: "icofont-dashboard-web",
      pathname: "/dashboard",
    },
    {
      title: "Categories",
      icon: "icofont-listing-number",
      pathname: "/categories",
    },
    {
      title: "Gallery",
      icon: "icofont-photobucket",
      pathname: "/gallery",
    },
    {
      title: "Solutions",
      icon: "icofont-tools-alt-2",
      pathname: "/solutions",
    },
  ];
  if (groups.includes("Developers")) {
    return [
      ...paths,
      {
        title: "Meta Infomation",
        icon: "icofont-code-alt",
        pathname: "/meta-info",
      },
    ];
  }
  return paths;
};
