//Importing helper functions
import api from "utils/axios.config";
import { Auth } from "aws-amplify";
import { getUrl } from "utils/modules";
import { partitionData } from "utils/modules";
import { update } from "utils/modules";
import { useEffect, useState } from "react";

//Importing core components
import { Fade } from "react-reveal";
import { Button, Grid, Pagination } from "@mui/material";
import { Dots } from "react-activity";
import { Store } from "react-notifications-component";
import { notification } from "Theme";
import Heading from "components/UI/Heading/Heading";
import Confirm from "components/UI/Confirm/Confirm";
import ImageUploader from "components/modules/ImageUploader/ImageUploader";
import Input from "components/modules/Input/Input";

//Importing styles
import styles from "./styles.module.css";

const ManagePhotos = ({ data, refetchData }) => {
  const [id, setId] = useState(null);
  const [page, setPage] = useState(0);
  const [form, setForm] = useState({ title: "", image: null });
  const [oldImg, setOldImage] = useState(null);
  const [photos, setPhotos] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data) {
      setPhotos(partitionData(data, 6));
    }
  }, [data]);

  const deleteItem = (id, value) => {
    if (value) {
      console.log(id);
    }
    setId(null);
  };

  const submit = async () => {
    const payload = {
      heading: form.title,
      image: form.image,
    };
    setLoading(true);
    try {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      const res = await api.post(
        "/gallery",
        { ...payload },
        {
          headers: { Authorization: token },
        }
      );
      Store.addNotification({
        ...notification,
        title: "Done",
        type: "Success",
        message: res.data,
        onRemoval: () => refetchData(),
      });
    } catch (error) {
      Store.addNotification({
        ...notification,
        title: "Error",
        type: "danger",
        message: error.message,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Confirm
        open={confirm}
        message="Are you sure?"
        title="Delete"
        setOpen={setConfirm}
        onChange={(value) => deleteItem(id, value)}
      />
      <Fade bottom>
        <div className={styles.box}>
          <Heading title={`Available Photos.(${data.length})`} />
          {photos && (
            <div className={styles.images}>
              <Grid container spacing={3}>
                {photos[page].map((item, i) => (
                  <Grid item xs={12} sm={6} md={4}>
                    <Fade delay={i > 2 ? (i - 2) * 200 : i * 200}>
                      <div className={styles.image}>
                        <div className={styles.edit}>
                          <Button
                            color="error"
                            variant="contained"
                            onClick={() => {
                              setId(item.SK);
                              setConfirm(true);
                            }}
                            size="small"
                          >
                            Delete
                          </Button>
                        </div>

                        <div
                          style={{
                            backgroundImage: `url("${getUrl(item.image)}")`,
                          }}
                        />
                      </div>
                    </Fade>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Pagination
                    color="primary"
                    count={photos.length}
                    onChange={(_, value) => setPage(value - 1)}
                  />
                </Grid>
              </Grid>
            </div>
          )}
          <Heading title={"Add New Photo"} />
          <div className={styles.form}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} sm={12} md={7}>
                <Input
                  icon="icofont-heading"
                  title="Title"
                  disabled={loading}
                  value={form.title}
                  onTextChange={(value) => update("title", value, setForm)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <ImageUploader
                  title="Image"
                  image={form.image}
                  oldImg={oldImg}
                  setOldImage={setOldImage}
                  md
                  updateFormField={setForm}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={6}>
                <button
                  className={styles.btn}
                  onClick={submit}
                  disabled={loading || !form.image}
                >
                  {loading ? (
                    <Dots color="#fff" size={16} speed={1} animating={true} />
                  ) : (
                    "Save Photo"
                  )}
                </button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default ManagePhotos;
