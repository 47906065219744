//Importing helper functions
import { Button } from "@mui/material";
import { getUrl } from "utils/modules";

//Importing styles
import styles from "./styles.module.css";

const ProductCard = ({ item, edit, editItem, deleteItem }) => {
  return (
    <div className={styles.product}>
      {edit && (
        <div className={styles.edit}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => editItem(item)}
            size="small"
          >
            Edit
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => deleteItem(item.SK)}
            size="small"
          >
            Delete
          </Button>
        </div>
      )}
      <div
        className={styles.image}
        style={{ backgroundImage: `url("${getUrl(item.image)}")` }}
      />
      <div className={styles.info}>
        <img src={getUrl(item.icon)} alt="logo" className={styles.icon} />
        <div>
          <h4>{item.tagline}</h4>
          <h3>{item.heading}</h3>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
