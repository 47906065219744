// import MDEditor from "@uiw/react-md-editor";
import MdEditor from "react-markdown-editor-lite";
import MarkdownIt from "markdown-it";
// import style manually
import "react-markdown-editor-lite/lib/index.css";
import styles from "./styles.module.css";

export default function Editor({ title, value, setValue }) {
  const mdParser = new MarkdownIt(/* Markdown-it options */);

  // Finish!
  function handleEditorChange({ text }) {
    setValue(text);
  }
  return (
    <div className="container">
      <label>{title}</label>
      <MdEditor
        value={value}
        className={styles.editor}
        renderHTML={(text) => mdParser.render(text)}
        onChange={handleEditorChange}
      />
    </div>
  );
}
